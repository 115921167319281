import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import {
	fetchCollaborationData,
	fetchCustomerInfo,
	updateCustomerInfo,
	fetchCustomerCountries,
	fetchCustomerDocuments,
	fetchCustomerDocument,
	fetchManagerStructure,
	fetchCustomerLocations,
} from "actions/customerActions";
import { RootState } from "store";
import {
	CustomerInfo,
	Customer,
	CustomerDTO,
	ShippingCountryAlternative,
	DocumentDownloadStatus,
	CustomerCountry,
	Document,
	ManagerStructure,
	CustomerLocation,
} from "types";
import { COLLABORATION_FOLDER_PATH } from "utilities/constants/constants";
import { DocumentType, DownloadFileStatusEnum } from "utilities/constants/enums";
import { mapToViewModel } from "utilities/features/customer";
import { handleBase64ResponseAs } from "utilities/pdfUtils";

const customerAdapter = createEntityAdapter<Customer | { folderPath: string; [key: string]: any }>({
	selectId: ({ folderPath }) => folderPath,
});

const initialState = {
	...customerAdapter.getInitialState(),
	details: { isLoading: true, isFetching: false } as CustomerInfo & {
		isLoading: boolean;
		isFetching: boolean;
	},
	customerFiles: {
		isLoading: true,
		isFetching: false,
		documents: [] as Document[],
		documentTypes: [] as DocumentType[],
		downloadStatus: {} as DocumentDownloadStatus,
	},
	customerCountries: {
		isLoading: true,
		isFetching: false,
		countries: [] as CustomerCountry[],
	},
	managerStructure: {
		isLoading: true,
		isFetching: false,
		managers: {} as ManagerStructure,
	},
	locations: {
		isLoading: true,
		isFetching: false,
		locations: [] as CustomerLocation[],
	},
};

const customerSlice = createSlice({
	name: "customer",
	initialState,
	reducers: {
		setCustomer(state, { payload }) {
			customerAdapter.setOne(state, payload);
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchCollaborationData.pending, (state) => {
				const folderPath = COLLABORATION_FOLDER_PATH;
				customerAdapter.upsertOne(state, {
					folderPath,
					isFetching: true,
					isLoading: !(folderPath in state.entities),
				});
			})
			.addCase(fetchCollaborationData.fulfilled, (state, { payload }) => {
				const folderPath = COLLABORATION_FOLDER_PATH;
				customerAdapter.upsertOne(state, {
					...mapToViewModel(folderPath, payload),
					isFetching: false,
					isLoading: false,
				});
			})
			.addCase(fetchCollaborationData.rejected, (state) => {
				const folderPath = COLLABORATION_FOLDER_PATH;
				customerAdapter.upsertOne(state, {
					...mapToViewModel(folderPath, {} as CustomerDTO),
					isFetching: false,
					isLoading: false,
				});
			});
		builder
			.addCase(fetchCustomerInfo.pending, (state) => {
				state.details.isFetching = true;
			})
			.addCase(fetchCustomerInfo.fulfilled, (state, { payload }) => {
				const approvers = (payload.approvers as any)
					?.split(",")
					.filter(Boolean) // Remove empty strings
					.map((approver: string) => ({
						id: approver,
						displayName: approver,
						mail: approver,
					}));
				const formattedCustomerDetails = {
					...payload,
					shippingCountryAlternatives: payload.hasKomplettRelationship
						? [
								{ name: "Norway", countryKomplettId: payload.komplettId },
								{ name: "Sweden", countryKomplettId: payload.komplettIdSweden },
						  ]
						: ([] as ShippingCountryAlternative[]),
					approvers,
				};

				state.details = {
					...formattedCustomerDetails,
					isLoading: false,
					isFetching: false,
				};
			});
		builder
			.addCase(fetchCustomerLocations.pending, (state) => {
				state.locations.isFetching = true;
			})
			.addCase(fetchCustomerLocations.fulfilled, (state, { payload }) => {
				state.locations = {
					locations: payload,
					isLoading: false,
					isFetching: false,
				};
			});

		builder.addCase(updateCustomerInfo.fulfilled, (state, { payload, meta }) => {
			const approverMails = meta.arg.body?.approvers?.split(",")?.filter(Boolean);
			const approvers = approverMails.map((approver: string) => ({
				mail: approver,
				id: approver,
				displayName: approver,
			}));
			const headUserEmail = meta.arg.body?.headUserEmail;
			const primeUserEmail = meta.arg.body?.primeUserEmail;
			const mergedStates = {
				...state.details,
				...payload,
				approvers,
				headUserEmail,
				primeUserEmail,
			};
			state.details = mergedStates;
		});
		builder
			.addCase(fetchCustomerCountries.pending, (state) => {
				state.customerCountries.isFetching = true;
			})
			.addCase(fetchCustomerCountries.fulfilled, (state, { payload }) => {
				state.customerCountries = {
					countries: payload as any as CustomerCountry[],
					isLoading: false,
					isFetching: false,
				};
			});
		builder
			.addCase(fetchManagerStructure.pending, (state) => {
				state.managerStructure.isFetching = true;
			})
			.addCase(fetchManagerStructure.fulfilled, (state, { payload }) => {
				state.managerStructure = {
					managers: payload as any as ManagerStructure,
					isLoading: false,
					isFetching: false,
				};
			});
		builder
			.addCase(fetchCustomerDocuments.pending, (state) => {
				state.customerFiles.isFetching = true;
			})
			.addCase(fetchCustomerDocuments.fulfilled, (state, { payload }) => {
				const documents = payload.value.map((doc) => ({
					...doc,
					documentType: DocumentType.Agreement,
				}));
				state.customerFiles = {
					documents,
					isLoading: false,
					isFetching: false,
					downloadStatus: payload.value.reduce(
						(acc, document) => ({
							...acc,
							[document.id]: DownloadFileStatusEnum.NotInteractedOn,
						}),
						{},
					),
					documentTypes: payload.value.reduce(
						(acc, document) =>
							acc.includes(document.pageName) ? acc : [...acc, document.pageName],
						[] as DocumentType[],
					),
				};
			});
		builder
			.addCase(fetchCustomerDocument.pending, (state, { meta }) => {
				state.customerFiles.downloadStatus[meta.arg.attachmentId] =
					DownloadFileStatusEnum.Downloading;
			})
			.addCase(fetchCustomerDocument.fulfilled, (state, { payload, meta }) => {
				const { value } = payload as any;
				handleBase64ResponseAs(meta.arg.fileType, value.base64Encoding, value.title);
				state.customerFiles.downloadStatus[meta.arg.attachmentId] =
					DownloadFileStatusEnum.Downloaded;
			})
			.addCase(fetchCustomerDocument.rejected, (state, { meta }) => {
				state.customerFiles.downloadStatus[meta.arg.attachmentId] =
					DownloadFileStatusEnum.Error;
			});
	},
});

export const { setCustomer } = customerSlice.actions;

export const selectCustomer = (state: RootState) => state.customer;

export const customerSelectors = customerAdapter.getSelectors(selectCustomer);

export const selectCollaborationCustomer = (state: RootState) =>
	customerSelectors.selectById(state, COLLABORATION_FOLDER_PATH) ?? {
		isLoading: true,
		isFetching: false,
	};

export const selectCustomerDetails = (state: RootState) => state.customer.details;
export const selectCustomerCountries = (state: RootState) => state.customer.customerCountries;
export const selectCustomerLocations = (state: RootState) => state.customer.locations;
export const selectCustomerFiles = (state: RootState) => state.customer.customerFiles;
export const selectCustomerDownloadStatuses = (state: RootState) =>
	state.customer.customerFiles.downloadStatus;
export const selectManagerStructure = (state: RootState) => state.customer.managerStructure;

export const selectApprovers = createSelector(
	(state: RootState) => state.customer.details.approvers,
	(approvers) => approvers,
);

export const selectCustomerCountriesKeyedByCountryCode = createSelector(
	selectCustomerCountries,
	(customerCountries) =>
		customerCountries.countries.reduce(
			(acc, country) => ({ ...acc, [country.countryCode]: country }),
			{},
		) as Record<string, CustomerCountry>,
);

export default customerSlice.reducer;
