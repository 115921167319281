import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	DocumentResponse,
	AppRoleAssignment,
	ArchivalSettings,
	CustomerDTO,
	CustomerInfo,
	CustomerCountry,
	CustomerLocation,
} from "types";
import { COLLABORATION_FOLDER_PATH, SCOPES } from "utilities/constants/constants";
import { apiGet, apiPost, apiPut } from "utilities/api";
import { queryParams } from "utilities/urlUtils";

export const fetchCustomerInfo = createAsyncThunk(
	"customer/fetchCustomerInfo",
	apiGet<CustomerInfo>(
		(authConfig) => authConfig.FRESHSERVICE_API_ENDPOINTS.GET_CUSTOMER_INFO(),
		[SCOPES.FRESHSERVICE_READ],
	),
);

export const updateCustomerInfo = createAsyncThunk(
	"customer/updateCustomerInfo",
	apiPut<CustomerInfo>(
		(authConfig, { id }) => authConfig.FRESHSERVICE_API_ENDPOINTS.UPDATE_CUSTOMER_INFO(id),
		[SCOPES.FRESHSERVICE_WRITE],
	),
);

export const fetchAppRoleAssignments = createAsyncThunk(
	"customer/fetchAppRoleAssignments",
	apiGet<AppRoleAssignment[]>(
		(authConfig) => authConfig.MS_GRAPH_API_ENDPOINTS.GET_APP_ROLES_ASSIGNMENTS(),
		[SCOPES.GRAPH_READ],
	),
);

export const updateAppRoleAssignments = createAsyncThunk(
	"customer/updateAppRoleAssignments",
	apiPost(
		(authConfig) => authConfig.MS_GRAPH_API_ENDPOINTS.UPDATE_APP_ROLES_ASSIGNMENTS(),
		[SCOPES.GRAPH_WRITE],
	),
);

export const fetchCollaborationData = createAsyncThunk(
	"customer/fetchCollaborationData",
	apiGet<CustomerDTO>(
		(authConfig) =>
			authConfig.DATA_LAKE_API_ENDPOINTS.GET_CUSTOMER_DATA(COLLABORATION_FOLDER_PATH),
		[SCOPES.DATA_LAKE_READ],
	),
);

export const saveCustomer = createAsyncThunk(
	"customer/save",
	apiPost(
		(authConfig, { folderPath }) =>
			authConfig.DATA_LAKE_API_ENDPOINTS.POST_CUSTOMER_DATA(folderPath),
		[SCOPES.DATA_LAKE_WRITE],
	),
);

export const fetchGroupLifecyclePolicies = createAsyncThunk(
	"customer/fetchGroupLifecyclePolicies",
	apiGet<ArchivalSettings[]>(
		(authConfig) => authConfig.MS_GRAPH_API_ENDPOINTS.GET_GROUP_LIFECYCLE_POLICIES(),
		[SCOPES.GRAPH_READ],
	),
);

export const setGroupLifecyclePolicies = createAsyncThunk(
	"customer/setGroupLifecyclePolicies",
	apiPost(
		(authConfig) => authConfig.MS_GRAPH_API_ENDPOINTS.SET_GROUP_LIFECYCLE_POLICIES(),
		[SCOPES.GRAPH_WRITE],
	),
);

export const createTicket = createAsyncThunk(
	"customer/createTicket",
	apiPost(
		(authConfig) => authConfig.FRESHSERVICE_API_ENDPOINTS.TICKETS(),
		[SCOPES.FRESHSERVICE_WRITE],
	),
);

export const fetchCustomerDocuments = createAsyncThunk(
	"customer/fetchCustomerAgreements",
	apiGet<DocumentResponse>(
		(authConfig, { params: { pageName } }: any) =>
			authConfig.FUNCTION_API_ENDPOINTS.LIST_CUSTOMER_DOCUMENTS() + queryParams({ pageName }),
		[SCOPES.FUNCTION_READ],
	),
);

export const fetchCustomerDocument = createAsyncThunk(
	"customer/fetchCustomerDocument",
	apiGet(
		(authConfig, { attachmentId }) =>
			authConfig.FUNCTION_API_ENDPOINTS.GET_CUSTOMER_DOCUMENT(attachmentId),
		[SCOPES.FUNCTION_READ],
	),
);

export const fetchCustomerCountries = createAsyncThunk(
	"customer/fetchCustomerCountries",
	apiGet<CustomerCountry[]>(
		(authConfig) => authConfig.MS_GRAPH_API_ENDPOINTS.GET_COUNTRIES(),
		[SCOPES.GRAPH_READ],
	),
);

export const fetchManagerStructure = createAsyncThunk(
	"customer/fetchManagerStructure",
	apiGet(
		(authConfig) => authConfig.MS_GRAPH_API_ENDPOINTS.GET_MANAGER_STRUCTURE(),
		[SCOPES.GRAPH_READ],
	),
);

export const fetchCustomerLocations = createAsyncThunk(
	"customer/fetchCustomerLocations",
	apiGet<CustomerLocation[]>(
		(authConfig) => authConfig.HARDWARE_API_ENDPOINTS.GET_CUSTOMER_LOCATIONS(),
		[SCOPES.HARDWARE_READ],
	),
);
