import { Autocomplete, Grid, InputLabel, TextField, Typography } from "@mui/material";
import { selectCustomerLocations } from "features/customer";
import { useAppSelector } from "hooks/hooks";
import { CustomerLocation } from "types";

import styles from "./DeliveryLocationSelector.module.scss";
import { CountryFlag } from "components/Common/CountrySelector";
import { selectHardwarePurchaseType } from "features/hardware/hardwarePage";
import { useMemo } from "react";
import { HardwarePurchaseType } from "utilities/constants/enums";

interface DeliveryLocationSelectorProps {
	selectedDeliveryLocation: CustomerLocation | null;
	handleSelectDeliveryLocation: (location: CustomerLocation | null) => void;
}

export const DeliveryLocationSelector = ({
	selectedDeliveryLocation,
	handleSelectDeliveryLocation,
}: DeliveryLocationSelectorProps) => {
	const customerLocations = useAppSelector(selectCustomerLocations);
	const hardwarePurchaseType = useAppSelector(selectHardwarePurchaseType);

	const allowedOptions = useMemo(() => {
		if (hardwarePurchaseType === HardwarePurchaseType.Lease) {
			// With leasing, we can deliver to any location (in EU)
			return customerLocations.locations;
		} else {
			// Currently, straight purchases are only allowed in Norway
			return customerLocations.locations.filter((location) => location.countryCode === "NO");
		}
	}, [customerLocations.locations, hardwarePurchaseType]);

	return (
		<>
			<InputLabel>
				<Typography variant="body1" display="inline" mr={1}>
					Selected location:
				</Typography>
			</InputLabel>
			<Autocomplete
				options={allowedOptions}
				renderOption={(props, option) => <Option {...props} location={option} />}
				getOptionLabel={(option) => {
					return option.address;
				}}
				isOptionEqualToValue={(option: CustomerLocation, value: CustomerLocation) =>
					option.address === value.address
				}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="outlined"
						placeholder="Select a delivery location"
					>
						{selectedDeliveryLocation && (
							<Typography variant="description" color="text.secondary">
								{selectedDeliveryLocation.address}
							</Typography>
						)}
					</TextField>
				)}
				onChange={(_, value) => handleSelectDeliveryLocation(value)}
				value={selectedDeliveryLocation}
			/>
		</>
	);
};

const Option = (props: React.HTMLAttributes<HTMLLIElement> & { location: CustomerLocation }) => {
	const { location, ...rest } = props;
	return (
		<li {...rest}>
			<Grid container className={styles.option}>
				<Grid item xs={1}>
					<CountryFlag countryCode={location.countryCode} coloredBackground={false} />
				</Grid>
				<Grid item xs={11}>
					<Grid container>
						<Typography variant="body1">{location.city}</Typography>
					</Grid>
					<Grid item>
						<Typography variant="description">
							{location.address}, {location.postalCode}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</li>
	);
};
