import { Card, Grid, Skeleton, Typography } from "@mui/material";
import styles from "./BundleCard.module.scss";
import { SecondaryButton, PrimaryButton } from "components/Common/Buttons/Button";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

import { HardwareBundle, HardwareProduct } from "types";
import { addItemsToCart, productsSelectors, selectItemByIds } from "features/hardware/products";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import clsx from "clsx";
import { formatCostString } from "utilities/currency/numberFormattingUtility";
import { bundleCountBySku, countBundledItems, sortByCategoryAndPrice } from "../ProductList/util";
import { editBundle } from "features/hardware/hardwareBundles";
import { NAVIGATION_NAMES } from "utilities/constants/pages";
import { useAppNavigate } from "hooks/useAppNavigate";
import { tripleDotTruncateString } from "utilities/stringFormattingUtility";
import { InfoOutlined } from "@mui/icons-material";
import { HoverTooltip } from "components/Common/Tooltips";
import { getIcon } from "utilities/iconUtils/hardwareIconUtils";
import { selectHardwarePurchaseType, setHardwareDrawerOpen } from "features/hardware/hardwarePage";
import { HardwareDrawerType, HardwarePurchaseType } from "utilities/constants/enums";
import { StockIndicator } from "../StockIndicator";
import { theme } from "theme";
import useRole from "utilities/roleUtils/roleCheck";
import { useMemo } from "react";
import { calculateHardwarePrice } from "utilities/hardwareUtils/hardwareCostUtils";

interface BundleCardInputProps {
	isLoading?: boolean;
	bundle?: HardwareBundle;
	productStateLoading?: boolean;
	isOnboarding?: boolean;
}

const BundleCard = ({
	isLoading = false,
	isOnboarding = false,
	bundle,
	productStateLoading,
}: BundleCardInputProps) => {
	const bundleProducts = Object.values(bundle?.products ?? {});
	const products = useAppSelector(productsSelectors.selectEntities);
	const { isAdmin } = useRole();
	const anyProductsEOL = bundleProducts.some((product) => !products[product.sku]);

	const disabledDescription = useMemo(() => {
		let text = "";
		if (productStateLoading) return text;
		if (anyProductsEOL) text = "One or more products in this bundle is no longer available. ";
		if (!isAdmin) text += "Ask a portal admin to edit this bundle.";
		return text;
	}, [productStateLoading, anyProductsEOL, isAdmin]);

	const { navigateToPage } = useAppNavigate();
	const edit = (bundle: HardwareBundle) => {
		dispatch(editBundle(bundle));
		dispatch(setHardwareDrawerOpen({ drawerType: HardwareDrawerType.Bundle }));
		navigateToPage(NAVIGATION_NAMES.HARDWARE_EDIT_BUNDLE.path);
	};

	const dispatch = useAppDispatch();

	const productSkus = Object.keys(bundle?.products ?? {});
	const productsWithPriceInfo = useAppSelector(selectItemByIds(productSkus));
	const hardwarePurchaseType = useAppSelector(selectHardwarePurchaseType);

	const numberOfItemsInBundle = countBundledItems(bundleProducts);

	const productsToShow = bundleProducts.sort(sortByCategoryAndPrice).slice(0, 5);

	const numberOfProductsNotShown = numberOfItemsInBundle - countBundledItems(productsToShow);

	// TODO: Seems like priceNet is a string, not a number as the type implies
	const totalPrice = productsWithPriceInfo.reduce(
		(acc, product) =>
			acc + calculateHardwarePrice(product?.priceInfo, hardwarePurchaseType).price,
		0,
	);

	const maxDescriptionLength = 42;

	return (
		<>
			{!isLoading ? (
				<Card className={styles.card} key={bundle?.id}>
					<Grid container className={styles.ribbonContainer}>
						<Typography variant="body1" className={styles.ribbonText}>
							Bundle
						</Typography>
					</Grid>
					<Grid container className={styles.container}>
						<Grid container className={styles.topItemsContainer}>
							<Grid item className={styles.headerContainer}>
								<Typography variant="h2" className={styles.bundleName}>
									{bundle?.name}
								</Typography>
								<HoverTooltip
									title={"Bundle description"}
									description={bundle?.description}
									hide={
										Number(bundle?.description?.length) < maxDescriptionLength
									}
								>
									<span>
										<Typography
											variant="description"
											className={styles.bundleDescription}
											lineHeight={1.2}
										>
											{bundle?.description?.substring(
												0,
												maxDescriptionLength,
											)}
										</Typography>
										<Typography
											variant="description"
											className={styles.bundleDescription}
										>
											{bundle?.description?.substring(maxDescriptionLength)}
										</Typography>
									</span>
								</HoverTooltip>
							</Grid>
							<Grid container className={styles.bundleItemsContainer}>
								<ul className={styles.bundleItemList}>
									{productsToShow.map((product, index) => {
										const completeProductInfo = productsWithPriceInfo.find(
											(productWithPriceInfo) =>
												productWithPriceInfo?.sku === product.sku,
										) as HardwareProduct;

										const quantityInStock =
											parseInt(
												completeProductInfo?.availability?.quantity ?? "0",
											) ?? 0;

										const showOutOfStock =
											quantityInStock < product.count && !productStateLoading;

										return (
											<li key={product.sku} className={styles.bundleListItem}>
												<Grid container gap={2}>
													{
														getIcon(
															product.group,
															product.subGroup,
															product.description,
														).icon
													}
													<Grid item className={styles.productInfo}>
														<Typography
															variant="description"
															fontWeight={600}
															className={clsx(styles.greyTextColor, {
																[styles.outOfStockText]:
																	showOutOfStock,
															})}
															display="inline"
														>
															{`${
																product.count > 1
																	? `${product.count} x `
																	: ""
															}`}
														</Typography>
														<Typography
															variant="description"
															className={clsx(styles.greyTextColor, {
																[styles.outOfStockText]:
																	showOutOfStock,
															})}
															display="inline"
														>
															{`${
																index >= 4
																	? tripleDotTruncateString(
																			product.displayName,
																			33,
																	  )
																	: `${product.displayName}`
															}`}
														</Typography>
														{showOutOfStock && (
															<StockIndicator
																availability={
																	completeProductInfo?.availability
																}
																requestedQuantity={product.count}
																variant="absolute"
															/>
														)}
													</Grid>
												</Grid>
											</li>
										);
									})}
								</ul>
								{numberOfProductsNotShown > 0 && (
									<HoverTooltip
										title={`${numberOfItemsInBundle} items in bundle`}
										description={
											<ul className={styles.tooltipList}>
												{bundleProducts.map((product) => {
													return (
														<li key={product.sku}>
															{product.displayName}
														</li>
													);
												})}
											</ul>
										}
									>
										<Grid item className={styles.infoContainer}>
											<InfoOutlined className={styles.infoIcon} />
											<Typography
												variant="description"
												color="text.secondary"
											>{`${numberOfProductsNotShown} more items`}</Typography>
										</Grid>
									</HoverTooltip>
								)}
							</Grid>
						</Grid>
						<Grid container className={styles.bottomItemsContainer}>
							<Grid container className={styles.priceContainer}>
								{!productStateLoading ? (
									<>
										<Grid container className={styles.priceWrapper}>
											<Typography variant="h2" className={styles.priceText}>
												{formatCostString("", totalPrice, " ,- ")}
											</Typography>
											{hardwarePurchaseType ===
												HardwarePurchaseType.Lease && (
												<Typography variant="caption"></Typography>
											)}
										</Grid>
										<Typography
											variant="description"
											className={styles.greyTextColor}
											mr={2.6}
										>
											{hardwarePurchaseType === HardwarePurchaseType.Buy
												? "eks. mva"
												: "per month"}
										</Typography>
									</>
								) : (
									<Skeleton className={styles.stockSkeleton} />
								)}
							</Grid>
							<Grid container className={styles.buttonContainer}>
								<PrimaryButton
									size="medium"
									text="Add to cart"
									className={styles.addToCartButton}
									icon={<AddShoppingCartIcon />}
									isDisabled={productStateLoading || anyProductsEOL}
									disabledDescription={disabledDescription}
									variantStyle="contained"
									action={() => {
										dispatch(
											setHardwareDrawerOpen({
												drawerType: HardwareDrawerType.Cart,
												// ForceOpen on large screens, otherwise it will be closed when adding to cart
												forceOpen:
													window.innerWidth > theme.breakpoints.values.md
														? true
														: false,
											}),
										);
										dispatch(addItemsToCart(bundleCountBySku(bundle)));
									}}
								/>
								<SecondaryButton
									action={() => bundle && edit(bundle)}
									variantStyle="outlined"
									size="medium"
									isDisabled={isOnboarding}
									className={clsx({
										[styles.disabled]: isOnboarding,
									})}
									text={
										<Grid container className={styles.editButtonContainer}>
											<Typography>Edit bundle</Typography>
										</Grid>
									}
									hidden={!isAdmin}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Card>
			) : (
				<Skeleton variant="rectangular" className={styles.skeleton} />
			)}
		</>
	);
};

export { BundleCard };
